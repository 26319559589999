import fetch from 'isomorphic-fetch';
import moment from 'moment';

const VENUE_API_URL = process.env.REACT_APP_VENUE_API_URL || `https://venue-api.venuemonk.com`
// const VENUE_API_URL = `http://localhost:8600`;

export default function callVenueApi(endpoint, method = 'get', body) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${VENUE_API_URL}/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}

